import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pagePostId: String };

  connect() {
  }

  hide(event) {
    const {pagePostId} = event.target.dataset;

    fetch("/admin/website_page_posts/" + pagePostId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        valid_status: "invalid",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const button = event.target;
        button.classList.remove('btn-warning');
        button.classList.add("btn-success");
      });
  }

  show(event) {
    const {pagePostId} = event.target.dataset;

    fetch("/admin/website_page_posts/" + pagePostId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        valid_status: "valid",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const button = event.target;
        button.classList.remove('btn-info');
        button.classList.add("btn-success");
      });
  }
}
