import { Controller } from "stimulus";

export default class extends Controller {
  static values = { pagePostId: String };

  connect() {
  }

  optimized(event) {
    const {pageId} = event.target.dataset;

    fetch("/admin/website_keyword_pages/" + pageId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        optimization_status: "validated",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const button = event.target;
        button.classList.remove('btn-danger');
        button.classList.add("btn-success");
      });
  }
}
