import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text", "arrow"];
  static values = {
    chevronUp: String,
    chevronRight: String
  }

  connect() {
    this.showSources = true;
    this.refreshArrow();
  }

  toggle(event) {
    this.showSources = !this.showSources;
    this.refreshArrow();
  }

  refreshArrow() {
    let hiddenClass = "hidden"
    if (this.showSources) {
      this.arrowTarget.src = this.chevronUpValue;
      this.textTarget.src = this.chevronUpValue;
      this.textTarget.classList.toggle(hiddenClass);
    } else {
      this.arrowTarget.src = this.chevronRightValue;
      this.textTarget.classList.toggle(hiddenClass);
    }
  }
}
