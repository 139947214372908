import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];
  static values = { currentArticleFolderId: String };

  connect() {
    this.fetchArticleFolders();
  }

  fetchArticleFolders() {
    fetch("/admin/article_folders")
      .then((response) => response.json())
      .then((data) => {
        this.selectTargets.forEach((select) => {
          data.forEach((articleFolder) => {
            const option = document.createElement("option");
            option.value = articleFolder.uuid;
            option.text = articleFolder.title;
            select.appendChild(option);
          });
        });
      });
  }

  update(event) {
    const pageId = event.target.dataset.pageId;

    fetch("/admin/website_keyword_pages/" + pageId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        article_folder_id: this.currentArticleFolderIdValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const button = event.target;
        button.classList.remove('btn-warning');
        button.classList.add("btn-success");
      });
  }

  handleChange(event) {
    this.currentArticleFolderIdValue = event.target.value;
    console.log(this.currentArticleFolderIdValue);
  }
}
