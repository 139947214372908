require.context('../assets/images', true);
require.context('../assets/fonts', true, /\.(eot|ttf|woff|woff2|otf)$/i);
import '../assets/stylesheets/website'

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../../javascript/controllers/", true, /\.js$/);
application.load(definitionsFromContext(context));

$(document).ready(function(){
  $(".hlk").each(function(){
    var klass = $(this).attr('class').split(/\s+/).pop();
    $(this).attr("href",atob(klass));
    var attributes = $(this).prop("attributes");
    var newtag= ($('<a/>').html($(this).html()));
    $.each(attributes, function(){
      newtag.attr(this.name, this.value)
    });
    $(this).replaceWith(newtag)
  });
  // Smooth scrolling using jQuery easing
  $('a.js-scroll-to[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top)
        }, 500, "swing");
        return false;
      }
    }
  });
});

$(document).ready(function(){

  // Carousel avis
  $('.slick-opinion').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    infinite: false,
    initialSlide: 2,
    centerPadding: '0px',
    dots: false,
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        }
      }
    ]
  });

});
